import { useState, useEffect } from 'react'

import { paymentMethodTypes, paymentMethodTypesArgExclusive } from '../../../variables'
import { getEntityNames, getEntities } from 'api/EntitiesAPI'

const usePaymentMethod = ({
  FORM,
  IS_ARG,
  isOpen,
  allPaymentMethodsSelected,
  setAllPaymentMethodsSelected,
  allCreditCard,
  setAllCreditCard,
}) => {
  // Metodos de pagos disponibles
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(paymentMethodTypes)
  // Actual metodo de pago, cual se esta modificando
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(undefined)
  // Opciones para el metodo de pago actual
  const [optionsForCurrentPaymentMethod, setOptionsForCurrentPaymentMethod] = useState([])
  // Opciones Seleccionadas para el metodo de pago actual
  const [optionsSelectedForCurrentPaymentMethod, setOptionsSelectedForCurrentPaymentMethod] = useState([])

  //Locales sin guardar
  // Opciones Seleccionadas en todos los metodos de pago (sin tarjeta de credito)
  const [allPaymentMethodsSelectedLocal, setAllPaymentMethodsSelectedLocal] = useState({})
  //Seleciones de tarjeta de credito
  const [allCreditCardLocal, setAllCreditCardLocal] = useState({})

  // Medio de pago: Tarjeta, cuotas seleccionadas
  const [fees, setFees] = useState([])
  // Estado para saber si esta cargando datos
  const [loading, setLoading] = useState(false)
  // Estado para saber si carga datos en el input
  const [inputLoading, setInputLoading] = useState(false)
  const [listPaymentMethodFiltrada, setListPaymentMethodFiltrada] = useState(false)
  //cantidad de medios de pago
  const [objectsSize, setObjectsSize] = useState(0)

  // Para determinar cual medio de pago se abre
  const [childOpen, setChildOpen] = useState(null)

  // Para saber si estas editando una credit card
  const [isEditing, setIsEditing] = useState(false)

  // Elemento que se esta editando
  const [selectedToEdit, setSelectedToEdit] = useState(null)

  // Efecto para setear las opciones de la tarjeta a editar
  useEffect(() => {
    if (!selectedToEdit) return
  
    deleteCreditCard(selectedToEdit)

    const response = optionsForCurrentPaymentMethod.find(item => item.value === selectedToEdit.value)
    if (response) {
      setFees(selectedToEdit.fees.map(v => ({ label: v, value: v })))
      setOptionsSelectedForCurrentPaymentMethod(response)
    }
  }, [selectedToEdit, optionsForCurrentPaymentMethod])

  useEffect(() => {
    setCurrentPaymentMethod(undefined)
    setOptionsForCurrentPaymentMethod([])
  }, [isOpen])

  // Función para cargar las opciones de los metodos de pago.

  useEffect(() => {
    setAllPaymentMethodsSelectedLocal(allPaymentMethodsSelected)
    setAllCreditCardLocal(allCreditCard)
  }, [allCreditCard, allPaymentMethodsSelected])

  useEffect(() => {
    setObjectsSize(
      Object.keys(allPaymentMethodsSelectedLocal).length + (Object.keys(allCreditCardLocal).length > 0 ? 1 : 0)
    )
  }, [allCreditCardLocal, allPaymentMethodsSelectedLocal])

  // Función para saber la longiud de cada item
  const getValuesLength = item => {
    if (item.value === 'CREDIT_CARD') return Object.keys(allCreditCardLocal)
    return allPaymentMethodsSelectedLocal[item.value]
  }

  // Función para eliminar todos los metodos de pagos seleccionados
  const removePaymentMethods = () => {
    setAllCreditCard({})
    setAllPaymentMethodsSelected({})
  }

  // Función para eliminar un método de pago actual
  const deleteCurrentPaymentMethod = paymentMethod => {
    let value = paymentMethod

    if (paymentMethod === currentPaymentMethod?.value || !paymentMethod || isEditing)  {
      value = currentPaymentMethod.value
    }

    const { [value]: toDelete, ...restOfElements } = allPaymentMethodsSelectedLocal

    if (restOfElements[value]?.lenght === 0) {
      delete restOfElements[value]
    }
    setAllPaymentMethodsSelectedLocal(restOfElements)

    if (!paymentMethod || paymentMethod === currentPaymentMethod?.value) {
      setFees([])
      setOptionsSelectedForCurrentPaymentMethod(undefined)
    }
  }

  const deleteCreditCard = element => {
    setAllCreditCardLocal(prevCreditCards => {
      const updatedCreditCards = { ...prevCreditCards }
      delete updatedCreditCards[element.value]
      return updatedCreditCards
    })
  }

  // Función para buscar el nombre de los metodos de pago
  const precargarPayments = async listPayment => {
    try {
      const processedPayments = await Promise.all(
        listPayment.map(async element => {
          let auxEntityType = paymentMethodTypesArgExclusive.find(p => p.value === element.type_entity_id)
          setLoading(false)
          try {
            let { data } = await getEntityNames(auxEntityType.endpoint, element.entity_id)
            let formattedData = data.map(({ id, description, fees }) => ({
              value: id,
              label: description,
              fees: element.fees,
            }))
            return {
              value: element.type_entity_id,
              name: auxEntityType.name,
              id: formattedData,
            }
          } catch (err) {
            console.error(err)
            return {
              value: element.type_entity_id,
              name: auxEntityType.name,
              id: null,
            }
          }
        })
      )
      setLoading(false)
      setInputLoading(false)
      let creditCardAll = {}
      processedPayments.forEach(preloadedPaymentMethod => {
        if (preloadedPaymentMethod.value === 'CREDIT_CARD') {
          if (preloadedPaymentMethod?.id) {
            preloadedPaymentMethod.id.forEach(creditCard => {
              creditCardAll = {
                ...creditCardAll,
                [creditCard.value]: { ...creditCard },
              }
            })
          }
        } else {
          setAllPaymentMethodsSelected(prevValues => {
            if (prevValues[preloadedPaymentMethod.value]) {
              return {
                ...prevValues,
                [preloadedPaymentMethod.value]: [
                  ...prevValues[preloadedPaymentMethod.value],
                  ...preloadedPaymentMethod.id,
                ],
              }
            } else {
              return {
                ...prevValues,
                [preloadedPaymentMethod.value]: [...preloadedPaymentMethod.id],
              }
            }
          })
        }
      })
      setAllCreditCard(creditCardAll)
    } catch (error) {
      console.error('Error al procesar los pagos:', error)
      throw error
    }
  }

  // Función para setear los metodos de pagos previos
  const setPrevValues = () => {
    setInputLoading(true)
    setAllPaymentMethodsSelectedLocal(allPaymentMethodsSelected)
    setAllCreditCardLocal(allCreditCard)
    setFees([])
    setInputLoading(false)
  }

  const setSaveValues = () => {
    let aaux = JSON.parse(JSON.stringify(allCreditCardLocal))
    if (optionsSelectedForCurrentPaymentMethod !== undefined && fees !== null && fees.length > 0) {
      let aaux = JSON.parse(JSON.stringify(allCreditCardLocal))
      const feesValue = fees.map(fee => fee.value)
      let auxCard = {
        value: optionsSelectedForCurrentPaymentMethod.value,
        label: optionsSelectedForCurrentPaymentMethod.label,
        fees: feesValue,
      }
      let aaux2 = {
        ...aaux,
        [optionsSelectedForCurrentPaymentMethod.value]: auxCard,
      }
      setAllCreditCard(aaux2)
    } else setAllCreditCard(aaux)
    setInputLoading(true)
    setAllPaymentMethodsSelected(removeNullKeys(allPaymentMethodsSelectedLocal))
    setAllPaymentMethodsSelectedLocal(removeNullKeys(allPaymentMethodsSelectedLocal))
    setInputLoading(false)
  }

  // Función para guardar credit card
  const saveCreditCards = () => {
    setSelectedToEdit(null)
    const feesValue = fees.map(fee => fee.value)
    let auxCard = {
      value: optionsSelectedForCurrentPaymentMethod.value,
      label: optionsSelectedForCurrentPaymentMethod.label,
      fees: feesValue,
    }
    setAllCreditCardLocal(aux => ({
      ...aux,
      [optionsSelectedForCurrentPaymentMethod.value]: auxCard,
    }))

    setFees([])
    setOptionsSelectedForCurrentPaymentMethod(undefined)
  }

  // UseEffect para setear los metodos de pagos dependiendo el pais
  useEffect(() => {
    if (IS_ARG) {
      return setAvailablePaymentMethods(paymentMethodTypesArgExclusive)
    }
    setAvailablePaymentMethods(availablePaymentMethods)
  }, [IS_ARG])

  // UseEffect para el caso de que ya hay metodos de pagos seleccionados
  useEffect(() => {
    setInputLoading(true)
    const { payments } = FORM
    if (payments && Array.isArray(payments)) {
      precargarPayments(payments)
    }
    setInputLoading(false)
  }, [FORM])

  // UseEffect para cambiar las opciones dependiendo el metodo de pago
  useEffect(() => {
    const getCurrentOptions = async () => {
      setLoading(true)
      if (currentPaymentMethod !== undefined) {
        getEntities(currentPaymentMethod.endpoint)
          .then(({ data: { content } }) => {
            setLoading(false)
            setOptionsForCurrentPaymentMethod(
              content.map(({ id, description, fees }) => ({ value: id, label: description, fees: fees }))
            )
          })
          .catch(err => console.log(err))
      } else {
        setLoading(false)
      }
    }
    if (currentPaymentMethod !== undefined && !isEditing) {
      setOptionsSelectedForCurrentPaymentMethod(allPaymentMethodsSelectedLocal[currentPaymentMethod.value])
    }

    setOptionsForCurrentPaymentMethod([])
    getCurrentOptions()
  }, [currentPaymentMethod])

  // UseEffect que escucha cambios y setea a todos los metodos de pagos
  useEffect(() => {
    if (
      currentPaymentMethod === undefined ||
      optionsForCurrentPaymentMethod === undefined ||
      optionsSelectedForCurrentPaymentMethod === undefined
    ) {
      return
    }

    if (currentPaymentMethod.value === 'CREDIT_CARD') {
      return
    }

    setAllPaymentMethodsSelectedLocal(aux => ({
      ...aux,
      [currentPaymentMethod.value]: optionsSelectedForCurrentPaymentMethod,
    }))
  }, [optionsSelectedForCurrentPaymentMethod])

  useEffect(() => {
    const paymentMethodOptionsFiltrados = () => {
      let paymentMethodOptionsFilter = optionsForCurrentPaymentMethod
      if (currentPaymentMethod?.value === 'CREDIT_CARD') {
        let auxlistPlaymentUsed = Object.keys(allCreditCardLocal)
        if (auxlistPlaymentUsed && auxlistPlaymentUsed.length > 0 && optionsForCurrentPaymentMethod.length > 0) {
          paymentMethodOptionsFilter = optionsForCurrentPaymentMethod.filter(
            elementOptions => !auxlistPlaymentUsed.includes(elementOptions.value)
          )
        }
      }
      return paymentMethodOptionsFilter
    }
    setListPaymentMethodFiltrada(paymentMethodOptionsFiltrados())
  }, [allCreditCardLocal, currentPaymentMethod, optionsForCurrentPaymentMethod])

  function removeNullKeys(obj) {
    const updatedPaymentMethods = { ...obj }
    for (let key in updatedPaymentMethods) {
      if (updatedPaymentMethods[key] === null) {
        delete updatedPaymentMethods[key]
      }
    }
    return updatedPaymentMethods
  }

  function validateCreditCard(index, item, callback) {
    setIsEditing(false)
    setSelectedToEdit(null)
    if (currentPaymentMethod?.value === 'CREDIT_CARD') {
      if (optionsSelectedForCurrentPaymentMethod !== undefined && fees !== null && fees.length > 0) {
        saveCreditCards()
        callback()
      } else if (optionsSelectedForCurrentPaymentMethod !== undefined || (fees !== null && fees.length > 0)) {
        alert('Complete la tarjeta o borre seleccion') // Cambiar por toast
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  const validCreditCard = (index, item) => {
    validateCreditCard(index, item, () => {
      setChildOpen(childOpen === index ? null : index)
      setCurrentPaymentMethod(childOpen === index ? undefined : item)
    })
  }

  const openCreditCard = (item, index) => {
    validateCreditCard(index, item, () => {
      setIsEditing(true)
      setChildOpen(index)
      setCurrentPaymentMethod(item)
      setSelectedToEdit(item.id[0])
    })
  }

  return {
    listPaymentMethodFiltrada,
    optionsSelectedForCurrentPaymentMethod,
    optionsForCurrentPaymentMethod,
    allPaymentMethodsSelectedLocal,
    availablePaymentMethods,
    currentPaymentMethod,
    allCreditCardLocal,
    inputLoading,
    objectsSize,
    childOpen,
    loading,
    fees,
    setFees,
    setChildOpen,
    setPrevValues,
    setSaveValues,
    openCreditCard,
    saveCreditCards,
    getValuesLength,
    validCreditCard,
    deleteCreditCard,
    removePaymentMethods,
    setCurrentPaymentMethod,
    deleteCurrentPaymentMethod,
    setOptionsSelectedForCurrentPaymentMethod,
  }
}

export default usePaymentMethod
