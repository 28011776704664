import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validIdsURLElement } from 'utils/regularExpresion'
import './radioCheckAttribute.css'
import useToast from 'components/Toast/hooks/useToast'
import Toast from 'components/Toast/Toast'

const RadioCheckAttribute = props => {
  const [tilde, setTilde] = useState(props.actives.find(e => e.value === props.entity.value) !== undefined) //tildado
  const { toast, showToast } = useToast()
  const maxLengthString = 36
  const { t } = useTranslation('createPromo')

  useEffect(() => {
    setTilde(props.actives.find(e => e.value === props.entity.value) !== undefined)
  }, [props.actives])

  function handleCheckbox() {
    let arrayInvalid = validIdsURLElement([{ value: props.entity.value, label: props.entity.label }])
    if (arrayInvalid.length > 0) {
      showToast({
        text:  t('steps.conditions.error.incorrectCode'),
        type: 'error',
      })

      setTilde(false)
    } else {
      setTilde(aux => {
        if (!aux) {
          props.setActives(aux => [...aux, props.entity])
          props.setIdsActiveLocal(aux => [...aux, props.entity.value])
        } else {
          props.setActives(aux => aux.filter(element => element.value !== props.entity.value))
          props.setIdsActiveLocal(aux => aux.filter(element => element !== props.entity.value))
        }
        return !aux
      })
    }
  }

  return (
    <div className={`radioCheckPadre ${props.entity.lineBreak ? 'lineBreak' : ''}`} onClick={handleCheckbox}>
      {toast && (
        <Toast
          {...toast}
          portalRef={document.querySelector('body')}
          className={'RadioCheckToast'}
        />
      )}
      <div
        className="RadioCheckAttributeContainer"
        id="c1"
        style={{
          margin: 0,
          padding: 0,
          color: '#2F2E3C',
          fontWeight: '600',
          fontSize: '16px',
        }}>
        <input
          type="checkbox"
          className="checkbox"
          value={props.active}
          checked={tilde}
          onChange={() => setTilde(!tilde)}
        />

        <span className="checkmark"></span>
      </div>
      <div className="RadioCheckAttributeContainer-descripcion">
        {/* {props.entity.label.length > maxLengthString && (
          <>
            <span className={'tooltiptext-descripcion'}>{props.entity.label}</span>
          </>
        )} */}
        {props.entity.lineBreak ? (
          <div className="RadioCheckAttributeContainer-lineBreak-div">
            <li>
              {props.entity.lineBreak ? <span>{props.entity.value}</span> : null}
              <p>{props.entity.label || ' - '}</p>
            </li>
          </div>
        ) : (
          <div className="RadioCheckAttributeContainer-descripcion-div">
            <li>{props.entity.label || ' - '}</li>
          </div>
        )}
      </div>
    </div>
  )
}

export default RadioCheckAttribute
