import React from 'react'
import Select, { components } from 'react-select'
import PropTypes from 'prop-types'
import { Option } from 'components/PromoWizard/Steps/GeneralSettingsForm/Components'

const noop = () => {
  // no operation (do nothing real quick)
}

class FixRequiredSelect extends React.Component {
  state = {
    value: this.props.value || '',
  }

  selectRef = null

  setSelectRef = ref => {
    this.selectRef = ref
  }

  onChange = (value, actionMeta) => {
    this.props.onChange(value, actionMeta)
    this.setState({ value })
  }

  getValue = () => {
    if (this.props.value !== undefined) return this.props.value
    return this.state.value || ''
  }

  render() {
    const { SelectComponent, required, labelList, optionCheck = false, ...props } = this.props
    const { isDisabled } = this.props
    const enableRequired = !isDisabled

    return (
      <div>
        <SelectComponent
          components={
            optionCheck
              ? {
                  Option: props => <Option {...props} />,
                  MultiValue: createMultiValue(labelList, this.onChange),
                }
              : {
                  MultiValue: createMultiValue(labelList, this.onChange),
                }
          }
          {...props}
          ref={this.setSelectRef}
          onChange={this.onChange}
        />
        {enableRequired && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              position: 'absolute',
            }}
            value={this.getValue()}
            onChange={() => {}}
            onFocus={() => this.selectRef.focus()}
            required={required}
          />
        )}
      </div>
    )
  }
}

FixRequiredSelect.defaultProps = {
  onChange: () => {},
  labelList: 'seleccionados', // Valor por defecto
}

FixRequiredSelect.propTypes = {
  SelectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  labelList: PropTypes.string, // Asegúrate de definir la prop aquí
}

export default FixRequiredSelect

const createMultiValue =
  (labelList, onChange) =>
  ({ index, getValue, ...props }) => {
    const values = getValue()
    const maxToShow = 2

    const handleClearAll = event => {
      onChange([], { action: 'clear' })
      event.stopPropagation()
    }

    if (values.length > maxToShow && index === 0) {
      return (
        <div className="MultiValueCustom">
          <div>{`${values.length} ${labelList}`}</div>
          <button onClick={handleClearAll}>
            <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg">
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </button>
        </div>
      )
    }

    if (values.length > maxToShow) {
      return null
    }

    return <components.MultiValue {...props} />
  }
